
import InlineSvg from 'vue-inline-svg';

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { mdiEye, mdiEyeOff } from '@mdi/js';
//import md5 from "js-md5";
import user_mixin from '~/mixins/user_mixin';
import shop_mixin from '@/mixins/shop_mixin';
import { onUserLogin } from '@/lib/clevertap';
import { mapMutations } from 'vuex';
import error_reporting, { MODULES } from '../mixins/error_reporting';

export default {
  mixins: [validationMixin, user_mixin, shop_mixin, error_reporting],
    components: {
        InlineSvg
    },
    layout: 'default',
    data() {
        return {
            submitted: false,
            email: '',
            password: '',
            showPassword: false,
            snackbar: false,
            isLoading: false,
            snackbarText: 'Username/Password incorrect.',
            snackbarTimeout: 2000,
            snackbarColor: 'info',
            dialog: false,
            id: 0,
            deepLinkUrl: null,
            redirectUrl: '/parents/child-profile',
            signupUrl: '/subscribe',
            params: '',
            icons: {
                mdiEye,
                mdiEyeOff
            }
        };
    },
    computed: {
        emailErrors() {
      try {
            if (this.submitted) {
                const errors = [];
                if (!this.$v.email.$dirty) return errors;
                !this.$v.email.email && errors.push('Must be valid e-mail');
                !this.$v.email.required && errors.push('E-mail is required');
                return errors;
            }
      } catch (err) {
        this.reportError({ tags: { module: MODULES.LOGIN }, error: err });
        throw err;
      }
        },
        passwordErrors() {
      try {
            if (this.submitted) {
                const errors = [];
                if (!this.$v.email.$dirty) return errors;
                !this.$v.password.required &&
                    errors.push('Password is required');
                return errors;
            }
      } catch (err) {
        this.reportError({ tags: { module: MODULES.LOGIN }, error: err });
        throw err;
      }
        }
    },
    validations: {
        email: { required, email },
        password: { required }
    },
    mounted() {
    try {
        if (this.$auth.loggedIn) {
            this.$router.replace('/parents/mykids');
        }
        this.deepLinkUrl = this.$cookies.get('deepLinkUrl');
        const keys = Object.keys(this.$route.query);
        if (keys.length) {
            const key = keys.find(
                obj => obj.toLowerCase().indexOf('code') >= 0
            );
            if (key) {
                this.params = key.toLowerCase();
            }
        }
    } catch (err) {
      this.reportError({ tags: { module: MODULES.LOGIN }, error: err });
      throw err;
    }
    },
    methods: {
        ...mapMutations('global', ['setCleverTapSession']),
        gotoSignup() {
      try {
        if (this.params === 'giftcode') {
                this.signupUrl = `/claim-your-gift?${this.params}=${
                    this.$route.query[this.params]
                }`;
            }
            let url = this.signupUrl;
            const redirectTo = this.$route.query.redirectTo;
            if (redirectTo && redirectTo === 'cart') {
                url = this.signupUrl + '?redirectTo=cart';
            }
            this.$router.replace(url);
      } catch (err) {
        this.reportError({ tags: { module: MODULES.LOGIN }, error: err });
        throw err;
      }
        },
        gotoForgotPassword() {
            let path;
            if (this.email != '') path = `/forgotpassword?email=${this.email}`;
            else path = `/forgotpassword`;

            this.$router.replace(path);
        },
        async preLogin() {
      try {
            switch (this.params) {
                case 'emailcode':
                    const status = await this.verifyEmail();
                    if (!status) {
                        return;
                    } else {
                        console.log('verification completed continue');
                    }
                    this.redirectUrl = '/parents/mykids';
                    break;
                case 'giftcode':
                    this.redirectUrl = `/parents/claimgift?${this.params}=${
                        this.$route.query[this.params]
                    }`;
                    break;
                case 'referralcode':
                    this.redirectUrl = '/myplan';
                    break;
                default:
                    break;
            }
            this.login();
      } catch (err) {
        this.reportError({ tags: { module: MODULES.LOGIN }, error: err });
        throw err;
      }
        },
        async login() {
      try {
            this.submitted = true;

            this.$v.$touch();
            if (!this.$v.$error) {
                this.isLoading = true;

                try {
                    await this.$auth.loginWith('local', {
                        data: {
                            strategy: 'local',
                            email: this.email,
                            password: this.password
                        }
                    });
                    this.postLogin();
                } catch (err) {
                    if (err.response.status === 401) {
                        console.log('inside catch', err);
                        if (
                            err.response.data.message ===
                            'Please verify your email first'
                        ) {
                            this.id = err.response.data.data.id;
                            this.dialog = true;
                        } else {
                            this.snackbarText = err.response.data.message;
                            this.snackbarColor = 'error';
                            this.snackbar = true;
                        }
                    }
                    console.log('error===>', err);
                } finally {
                    this.isLoading = false;
                }
            }
      } catch (err) {
        this.reportError({ tags: { module: MODULES.LOGIN }, error: err });
        throw err;
      }
        },
        async postLogin() {
      try {
            onUserLogin(this.$auth.user);
            this.setCleverTapSession(true);
            const { role, email } = this.$auth.user;

            if (role === 'Parent') {
              this.$sentry.setUser({
                email,
                id: this.$auth.user.user_profile[0].id,
                childId:
                  this.$route.query.child_id ?? this.$auth.user.user_profile[0].id
              });
            }

            if (role === 'Admin') {
                this.$router.replace('/admin/questionSets');
            } else if (role === 'Coupons') {
                this.$router.replace('/coupon-users/coupons');
            } else if (role === 'Shop') {
                this.$router.replace('/shop-users/products');
            } else if (role === 'Advertiser') {
                this.$router.replace('/advertisers/dashboard');
            } else {
                if (
                    this.$auth.user.user_profile &&
                    this.$auth.user.user_profile.length
                ) {
                    const childId = this.$route.query.child_id;

                    let child;
                    if (childId) {
                        child = this.$auth.user.user_profile.find(
                            c => c.id === parseInt(childId)
                        );
                    }

                    if (!child) child = this.$auth.user.user_profile[0];

                    await this.setChild(child);

                    if (child.subscription_id > 0) {
                        this.redirectUrl = 'parents/practice';
                    }
                } else {
                    this.redirectUrl = 'parents/mykids';
                    this.setChild({});
                }
                switch (this.params) {
                    case 'giftcode':
                        const result = await this.$api.giftSubscription.update(
                            0,
                            {
                                controller: 'user-update',
                                user_id: this.$auth.user.id,
                                token: this.$route.query[this.params],
                                subscriber_email: this.$auth.user.email
                            }
                        );
                        this.redirectUrl = '/myplan';
                        break;
                    case 'friend_code':
                        const friend_code = this.$route.query[this.params];
                        if (friend_code) {
                            const codes = friend_code.split('-');
                            const user_profile_id = codes[0];
                            const friend_id = codes[1];
                            await await this.$api.friendRequests.update(0, {
                                user_profile_id,
                                friend_id,
                                status: 'Friend'
                            });
                        }
                        console.log('update friend request thanks');
                        break;
                    default:
                        break;
                }

                await this.mergeUnregisteredUserCart();
                const redirectTo = this.$route.query.redirectTo;
                if (redirectTo && redirectTo === 'cart') {
                    this.redirectUrl = '/shop/cart';
                }
                this.$router.replace(this.deepLinkUrl || this.redirectUrl);
                this.$cookies.set('deepLinkUrl', undefined);
              }
            } catch (err) {
              this.reportError({ tags: { module: MODULES.LOGIN }, error: err });
              throw err;
            }
        },
        async resendLink() {
      try {
            const response = await this.$api.users.patch(this.id, {
                controller: 'sendVarificationMail'
            });
            this.snackbarColor = 'info';
            this.snackbarText = response.message;
            this.snackbar = true;
            this.dialog = false;
      } catch (err) {
        this.reportError({ tags: { module: MODULES.LOGIN }, error: err });
        throw err;
      }
        },
        async verifyEmail() {
      try {
            return await this.$api.users.patch(0, {
                controller: 'emailVerified',
                email: this.email,
                email_verified: true,
                appcode: this.$route.query[this.params]
            });
      } catch (err) {
        this.reportError({ tags: { module: MODULES.LOGIN }, error: err });
        throw err;
      }
        }
    }
};
