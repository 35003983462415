import { events, trackEvent } from '@/lib/clevertap';
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('global', ['unregisteredUserId']),
    ...mapGetters('global', ['getChild']),
    registeredUser() {
      return this.$auth.loggedIn;
    },
    childId() {
      return this?.getChild?.id;
    }
  },
  methods: {
    async getCart() {
      const resp = await this.$api.cart.get(
        this.registeredUser ? this.childId : this.unregisteredUserId,
        {
          country_id: this.$auth?.user?.shopCountry?.id ?? 81
        }
      );
      return resp?.items ?? [];
    },
    async addItemToCart(item) {
      const requestData = {
        controller: 'addItem',
        ...item,
        childId: this.registeredUser ? this.childId : this.unregisteredUserId
      };
      await this.$api.cart.create(requestData);
    },
    async removeItemFromCart(item) {
      const requestData = {
        controller: 'removeItem',
        ...item,
        childId: this.registeredUser ? this.childId : this.unregisteredUserId
      };
      await this.$api.cart.create(requestData);
    },
    async subtractItemQuantity(item) {
      const requestData = {
        controller: 'subtractQuantity',
        ...item,
        childId: this.registeredUser ? this.childId : this.unregisteredUserId
      };
      await this.$api.cart.create(requestData);
    },
    async addItemQuantity(item) {
      const requestData = {
        controller: 'addQuantity',
        ...item,
        childId: this.registeredUser ? this.childId : this.unregisteredUserId
      };
      await this.$api.cart.create(requestData);
    },
    async mergeUnregisteredUserCart() {
      await this.$api.cart.create({
        controller: 'mergeUnregisteredUserCart',
        unregisteredUserId: this.unregisteredUserId,
        childId: this.registeredUser ? this.childId : this.unregisteredUserId
      });
    }
  }
};
